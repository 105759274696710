import React, { useState } from 'react';
import Sidebar from '../Includes/Sidebar';
import GeneralService from '../../services/general.service';
import Footer from '../Includes/Footer';
import { useFormik } from 'formik';
import { categorySchema } from '../schema';
import { useSelector } from 'react-redux';
import { AiOutlineCloudUpload } from 'react-icons/ai';

export default function AddCategory() {
    const [file, setFile] = useState(null);
    const [nameError, setNameError] = useState(null);
    const [message, setMessage] = useState("");
    const [progress, setProgress] = useState(0);
    const [showProgress, setShowProgress] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // New state for loading

    const state = useSelector((state) => state.stateVals);
    const { id: adminId } = state;

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } =
        useFormik({
            enableReinitialize: true,
            initialValues: {
                name: "",
                image: null,
            },
            validationSchema: categorySchema,
            onSubmit: (values, action) => {
                formSubmit(values, action);
            },
        });

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
        setFieldValue("image", selectedFile);
        setShowProgress(true);

        let simulatedProgress = 0;
        const interval = setInterval(() => {
            simulatedProgress += 10;
            setProgress(simulatedProgress);
            if (simulatedProgress >= 100) {
                clearInterval(interval);
                setShowProgress(false);
            }
        }, 200);
    };

    const formSubmit = async (values) => {
        setIsLoading(true); // Set loading state to true before submitting
        try {
            const response = await GeneralService.addCategory(values, adminId);
            setMessage("Category inserted successfully!");
            setFile(null);
            setProgress(0);
        } catch (err) {
            if (err?.response?.status === 422) {
                setNameError(err?.response?.data?.name[0]);
            } else {
                setMessage("Error inserting category");
            }
        } finally {
            setIsLoading(false); // Set loading state to false after submission
        }
    };

    return (
        <>
            <div className="content">
                <Sidebar />
                <div className="container-fluid pt-4 px-4">
                    <div className="bg-light text-center rounded p-4">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <h6 className="mb-0">Add Category</h6>
                        </div>
                        {message && (
                            <div className="alert alert-success animate__animated animate__fadeIn" role="alert">
                                {message}
                            </div>
                        )}
                        <form onSubmit={handleSubmit} noValidate>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-floating mb-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            name="name"
                                            placeholder="Name"
                                            value={values.name || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <label htmlFor="name">Name</label>
                                    </div>
                                    {errors.name && touched.name ? (
                                        <p className="text-danger">{errors.name}</p>
                                    ) : null}
                                    {nameError && (
                                        <p className="text-danger">{nameError}</p>
                                    )}
                                </div>
                                <div className="col-md-6">
                                    <div className="file-upload mb-4">
                                        <label
                                            className={`file-label ${file ? 'file-selected' : ''}`}
                                        >
                                            <AiOutlineCloudUpload className="file-icon" />
                                            <span>{file ? file.name : 'Choose Image'}</span>
                                            <input
                                                type="file"
                                                name="image"
                                                id="image"
                                                className="file-input"
                                                onChange={handleFileChange}
                                                onBlur={handleBlur}
                                            />
                                        </label>
                                        {file && (
                                            <p className="file-counter animate__animated animate__fadeIn animate__scaleIn">
                                                Image Selected!
                                            </p>
                                        )}
                                    </div>

                                    {errors.image && touched.image ? (
                                        <p className="text-danger">{errors.image}</p>
                                    ) : null}

                                    {/* Progress Bar */}
                                    {showProgress && (
                                        <div className="progress-bar-container">
                                            <div className="progress-bar-wrapper">
                                                <div className="progress-bar" style={{ width: `${progress}%` }}></div>
                                                <div className="progress-text">{progress}%</div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="col-md-12 d-flex justify-content-end">
                                    <button className="btn btn-submit" type="submit" disabled={isLoading}>
                                        {isLoading ? "Submitting..." : "Submit"}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <Footer />
            </div>
            <style jsx>{`
                .file-upload {
                    position: relative;
                    text-align: center;
                }
         
                .file-label {
                    display: inline-block;
                    padding: 12px 20px;
                    border: 2px dashed #f44c00;
                    border-radius: 10px;
                    color: #f44c00;
                    cursor: pointer;
                    transition: background-color 0.3s ease;
                    width: 100%;
                    text-align: center;
                }
                .file-label:hover {
                    background-color: rgba(255, 255, 255, 0.1);
                    border: 2px solid rgba(255, 255, 255, 0.5);
                }
                     .file-label.file-selected { 
                        border: 2px dashed #4CAF50; 
                        color: #4CAF50;  
                    }

                .file-icon {
                    font-size: 28px;
                    margin-right: 10px;
                }
                .file-input {
                    display: none;
                }
                .file-counter {
                    font-size: 14px;
                    margin-top: 8px;
                    color: #4CAF50;
                }

                .progress-bar-container {
                    margin-top: 10px;
                    width: 100%;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                }

                .progress-bar-wrapper {
                    width: 100%;
                    background: rgba(0, 0, 0, 0.1);
                    border-radius: 10px;
                    overflow: hidden;
                    height: 8px;
                    position: relative;
                }

                .progress-bar {
                    height: 100%;
                    background: #f44c00;
                    transition: width 0.3s ease;
                }

                .progress-text {
                    position: absolute;
                    top: 100%;
                    width: 100%;
                    font-size: 14px;
                    color: #f44c00;
                    font-weight: bold;
                    text-align: center;
                    margin-top: 5px;
                }

                .btn-submit {
                    background-color: #f44c00;
                    border-color: #f44c00;
                    padding: 14px 28px;
                    font-size: 16px;
                    font-weight: 600;
                    text-transform: uppercase;
                    border-radius: 30px;
                    transition: background-color 0.3s, border-color 0.3s;
                    display: inline-block;
                    margin-top: 10px;
                    color: #fff
                }

                .btn-submit:hover {
                    background-color: #d43f00;
                    border-color: #d43f00;
                    color: #fff;
                }

                .btn-submit:focus {
                    outline: none;
                }
            `}</style>
        </>
    );
}
