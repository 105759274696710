import React, { useEffect, useState } from 'react';
import Sidebar from '../Includes/Sidebar';
import GeneralService from '../../services/general.service';
import Footer from '../Includes/Footer';
import { useFormik } from 'formik';
import { ManualSchema } from '../schema';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

export default function ManualOrder() {
    const [categoryError, setCategoryError] = useState("");
    const [productError, setProductError] = useState("");
    const [message, setMessage] = useState("");
    const [categories, setCategories] = useState([]);
    const [userId, setUserId] = useState("");
    const [users, setUsers] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [prodImage, setProdImage] = useState("");
    const [prodPrice, setProdPrice] = useState("");
    const state = useSelector((state) => state.stateVals);
    const [isLoading, setIsLoading] = useState(false); // New state for loading
    const { id: adminId } = state;
    const [tableData, setTableData] = useState([]);

    const decreaseQty = (prodId) => {
        setTableData(prevTableData => {
            return prevTableData.map(item => {
                if (item.prod_id === prodId) {
                    const newQuantity = item.quantity > 1 ? item.quantity - 1 : 1; // Ensure quantity doesn't go below 1
                    return {
                        ...item,
                        quantity: newQuantity,
                        totalPrice: newQuantity * parseInt(item.product_price) // Update total price
                    };
                }
                return item;
            });
        });
    };
    
    const increaseQty = (prodId) => {
        setTableData(prevTableData => {
            return prevTableData.map(item => {
                if (item.prod_id === prodId) {
                    const newQuantity = item.quantity + 1;
                    return {
                        ...item,
                        quantity: newQuantity,
                        totalPrice: newQuantity * parseInt(item.product_price) // Update total price
                    };
                }
                return item;
            });
        });
    };
    
    

    const fetchCategories = async () => {
        try {
            const response = await GeneralService.listAllCategories();
            setCategories(response.data.response);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const placeOrder = () => {
        GeneralService.placeOrder(values.user);
        fetchTableData()
        // console.log("Order placed");
    }

    const fetchUsers = async () => {
        try {
            const response = await GeneralService.listAllUsers();
            const users = response.data.response.map(user => ({
                ...user,
                name: `${user.first_name} ${user.last_name}`,
                address: `${user.address}`
            }));
            setUsers(users);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const fetchProductsByCategoryId = async (categoryId) => {
        try {
            const response = await GeneralService.listProductsById(categoryId);
            setFilteredProducts(response.data.response);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    const fetchProductInfoById = async (prodId) => {
        try {
            const response = await GeneralService.getProductById(prodId);
            console.log(response);
            setProdImage(response.data.response.image);
            setProdPrice(response.data.response.price);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    const fetchTableData = async () => {
        try {
            const response = await GeneralService.listUserCart(values.user);
            setTableData(response.data.response);
        } catch (error) {
            setTableData([]);
            console.error('Error fetching table data:', error);
        }
    };

    useEffect(() => {
        fetchCategories();
        fetchUsers();
        fetchTableData();
    }, []);

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        enableReinitialize: true,
        initialValues: {
            user: "",
            category: "",
            product: "",
            address: "",  // Initialize the address field
        },
        validationSchema: ManualSchema,
        onSubmit: (values, action) => {
            formSubmit(values, action);
        },
    });

    // Fetch products when category changes
    useEffect(() => {
        if (values.category) {
            fetchProductsByCategoryId(values.category);
        } else {
            setFilteredProducts([]);
        }
    }, [values.category]);

    // Handle user selection and populate address field
    useEffect(() => {
        if (values.user) {
            const selectedUser = users.find(user => user.id === values.user);
            if (selectedUser) {
                setFieldValue('address', selectedUser.address);
            }
            fetchTableData(values.user);
        } else {
            setFieldValue('address', "");
        }
    }, [values.user]);

    useEffect(() => {
        if (values.product) {
            fetchProductInfoById(values.product);
        } else {
            setProdImage("");
            setProdPrice("");
        }
    }, [values.product]);

    useEffect(() => {
        if (values.user) {
            const selectedUser = users.find(user => user.id === parseInt(values.user));
            if (selectedUser) {
                setFieldValue('address', selectedUser.address || ''); // Set the address field value
            }
        } else {
            setFieldValue('address', ''); // Clear address if no user is selected
        }
    }, [values.user, users, setFieldValue]);

    const formSubmit = async (values, action) => {
        setMessage("");
        try {
            const formData = new FormData();
            formData.append('user', values.user);
            formData.append('category', values.category);
            formData.append('product', values.product);
            formData.append('address', values.address);
    
            console.log("Form values before submission:", values);
            console.log([...formData]); // Log formData to check the actual values being sent
            
            const response = await GeneralService.addCart(values, adminId);
            setMessage("Product added");
            fetchTableData();
        } catch (err) {
            if (err?.response?.status === 422) {
                const errorData = err?.response?.data;
                if (errorData?.user) setCategoryError(errorData.user[0]);
            } else {
                setMessage("Error adding product: " + (err?.response?.data?.message || err.message));
            }
        }
    };

    const remove = (id) => {
        Swal.fire({
            title: "Confirmation",
            text: "Are you sure you want to delete this product?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                deleteData(id);
            }
        });
    };

    const deleteData = async (id) => {
        try {
            await GeneralService.deleteCart(id, values.user);
            fetchTableData();
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <div className="content">
                <Sidebar />
                <div className="container-fluid pt-4 px-4">
                    <div className="bg-light text-center rounded p-4">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <h6 className="mb-0">Manual Order</h6>
                        </div>
                        {message && (
    <div className={`alert ${message.includes('successfully') ? 'alert-danger' : 'alert-success'}`} role="alert">
        {message}
    </div>
)}

                        <form onSubmit={handleSubmit} noValidate>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="form-floating mb-3">
                                        <select
                                            name="user"
                                            id="user"
                                            className="form-control"
                                            value={values.user || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        >
                                            <option value="">Select User</option>
                                            {users.map(user => (
                                                <option key={user.id} value={user.id}>{user.name}</option>
                                            ))}
                                        </select>
                                        <label htmlFor="user">User</label>
                                    </div>
                                    {errors.user && touched.user && (
                                        <p className="help is-danger">{errors.user}</p>
                                    )}
                                    {categoryError && (
                                        <p className="help is-danger">{categoryError}</p>
                                    )}
                                </div>

                                <div className='col-md-6'>
                                    <div className="form-floating mb-3">
                                        <select
                                            name="category"
                                            id="category"
                                            className="form-control"
                                            value={values.category || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        >
                                            <option value="">Select Category</option>
                                            {categories.map(row => (
                                                <option key={row.id} value={row.id}>{row.name}</option>
                                            ))}
                                        </select>
                                        <label htmlFor="category">Category</label>
                                    </div>
                                    {errors.category && touched.category && (
                                        <p className="help is-danger">{errors.category}</p>
                                    )}
                                    {categoryError && (
                                        <p className="help is-danger">{categoryError}</p>
                                    )}
                                </div>

                                <div className='col-md-6'>
                                    <div className="form-floating mb-3">
                                        <select
                                            name="product"
                                            id="product"
                                            className="form-control"
                                            value={values.product || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            disabled={!values.category} // Disable if no category is selected
                                        >
                                            <option value="">Select Product</option>
                                            {filteredProducts.map(product => (
                                                <option key={product.id} value={product.id}>{product.name}</option>
                                            ))}
                                        </select>
                                        <label htmlFor="product">Product</label>
                                    </div>
                                    {errors.product && touched.product && (
                                        <p className="help is-danger">{errors.product}</p>
                                    )}
                                    {productError && (
                                        <p className="help is-danger">{productError}</p>
                                    )}
                                </div>

                                <div className="col-md-6">
                                    <div className="form-floating mb-3">
                                    <input 
                                    type="text"
                                    className="form-control" 
                                    id="address" 
                                    name="address" 
                                    placeholder="Address" 
                                    value={values.address || "" } 
                                    onChange={handleChange} 
                                    onBlur={handleBlur}
                                    />
                                        <label htmlFor="ddress">Address</label>

                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className="form-floating mb-3">
                                        {prodImage && (<img src={`https://api.veggieking.pk/public/upload/${prodImage}`} alt="Product Image" style={{ width: "100px", height: "100px" }} />)}
                                        {prodPrice && (<><br /><strong>Price: Rs. {prodPrice}</strong></>)}
                                    </div>
                                </div>

                                <div className="col-md-12 d-flex">
                                    <button className="btn btn-submit" type="submit" disabled={isLoading}>
                                        {isLoading ? "Submitting..." : "Add Product"}
                                    </button>
                                </div>
                            </div>
                        </form>

                        {tableData && (
                            <>
                                <div className="d-flex align-items-center justify-content-between mb-4">
                                    <h5 className="mb-0">Total Cart Items <span className="number-rcords">({tableData.length})</span>
                                    </h5>
                                    {/* <button className="btn btn-primary" onClick={placeOrder} type='button'>Place Order</button> */}
                                <button className="btn btn-submit2" onClick={placeOrder} type="submit">
                                Place Order
                                    </button>
                                </div>
                                {/* <div className='col-md-12'>
                                    <div className='col-md-9'>
                                        <input
                                            type='text'
                                            name='delivery_address'
                                            className='form-control'
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                    </div>
                                </div> */}
                                <div className="table-wrapper">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>No.</th>
                                                <th>Image</th>
                                                <th>Name</th>
                                                <th>Price</th>
                                                <th>Quantity</th>
                                                <th>Total Price</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(tableData) && tableData.map((item, index) => (
                                                <tr key={index + 1}>
                                                    <td>{index + 1}</td>
                                                    <td style={{ width: "20%" }}>
                                                        <img src={`https://api.veggieking.pk/public/upload/${item.product_image}`}
                                                            alt="Product Image" style={{ width: '100px', height: '100px' }} />
                                                    </td>
                                                    <td>{item.product_name}</td>
                                                    <td>Rs. {item.product_price}</td>
                                                    <td>
                                                        <button className='btn btn-sm btn-primary' onClick={() => decreaseQty(item.prod_id)}>-</button>
                                                        {item.quantity}{" "}
                                                        <button className='btn btn-sm btn-primary' onClick={() => increaseQty(item.prod_id)}>+</button>
                                                    </td>

                                                    <td>Rs. {parseInt(item.quantity) * parseInt(item.product_price)}</td>
                                                    <td className="action-buttons">
                                                        <div className="button-group">
                                                            <a className="btn btn-sm btn-primary" onClick={() => remove(item.prod_id)} href="javascript:void(0)">Delete</a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        )}

                    </div>
                </div>
                <Footer />
            </div>
                 <style jsx>{`
                 .btn-submit {
                    background-color: #f44c00;
                    border-color: #f44c00;
                    padding: 14px 28px;
                    font-size: 16px;
                    font-weight: 600;
                    text-transform: uppercase;
                    border-radius: 30px;
                    transition: background-color 0.3s, border-color 0.3s;
                    display: inline-block;
                    margin-bottom: 20px;
                    color: #fff
                    }
                    .btn-submit:hover {
                        background-color: #d43f00;
                        border-color: #d43f00;
                        color: #fff;
                    }
    
                    .btn-submit:focus {
                        outline: none;
                    }
                       .btn-submit2 {
                    background-color: #f44c00;
                    border-color: #f44c00;
                    padding: 14px 28px;
                    font-size: 16px;
                    font-weight: 600;
                    text-transform: uppercase;
                    border-radius: 30px;
                    transition: background-color 0.3s, border-color 0.3s;
                    display: inline-block;
                    color: #fff
                }
                       .btn-submit2:hover {
                        background-color: #d43f00;
                        border-color: #d43f00;
                        color: #fff;
                    }
    
                    .btn-submit2:focus {
                        outline: none;
                    }

            `}</style>
        </>
    );
}
