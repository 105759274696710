// OnlineStatus.js
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

const OnlineStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const handleOnlineStatus = (status) => {
    setIsOnline(status);

    Swal.fire({
      icon: status ? 'success' : 'error',
      title: status ? '🌐 Back Online' : '⚠️ You\'re Offline',
      text: status
        ? 'You are back online!'
        : 'You are offline. Please check your internet connection.',
      timer: 3000,
      showConfirmButton: false,
      toast: true,
      position: 'top',
      customClass: {
        popup: 'glass-toast', // Apply custom glass effect styling
      }, // Semi-transparent white for glass effect
      color: '#333', // Text color
    });
  };

  useEffect(() => {
    const handleOnline = () => handleOnlineStatus(true);
    const handleOffline = () => handleOnlineStatus(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return null; // No UI needed as SweetAlert will handle the message display
};

<style jsx>{`
/* OnlineStatus.css */
.glass-toast {
  border-radius: 8px;
  padding: 10px 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: #fff; /* Semi-transparent background */
}

.swal2-icon.swal2-success, .swal2-icon.swal2-error {
  box-shadow: none; /* Remove default shadow */
}

`}</style>


export default OnlineStatus;
