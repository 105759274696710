import React, { useEffect, useState } from 'react'
import Sidebar from '../Includes/Sidebar'
import GeneralService from '../../services/general.service';
import Footer from '../Includes/Footer';
import { useFormik } from 'formik';
import { productSchema } from '../schema';
import { useSelector } from 'react-redux';
import { AiOutlineCloudUpload } from 'react-icons/ai';


export default function AddProducts() {
    const [file, setFile] = useState(null);
    const [error, setError] = useState(null);
    const [categoryError, setCategoryError] = useState("");
    const [nameError, setNameError] = useState("");
    const [descError, setDescError] = useState("");
    const [purchasePriceError, setPurchasePriceError] = useState("");
    // const [availableError, setAvailableError] = useState("");
    const [salePriceError, setSalePriceError] = useState("");
    const [imageError, setImageError] = useState("");
    const [scaleError, setScaleError] = useState("");
    const [message, setMessage] = useState("");
    const [categories, setCategories] = useState([]);
    const [progress, setProgress] = useState(0);
    const [showProgress, setShowProgress] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // New state for loading
    const state = useSelector((state) => state.stateVals);
    const { id: adminId } = state;


    const fetchData = async () => {
        try {
            const response = await GeneralService.listAllCategories();
            setCategories(response.data.response);
            console.log(response.data.response);
        } catch (error) {
            console.error('Error fetching table data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);



    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } =
        useFormik({
            enableReinitialize: true,
            initialValues: {
                category: "",
                name: "",
                description: "",
                image: null,
                sale_price: "",
                purchase_price: "",
                // available: "",
                // scale: "",
            },
            validationSchema: productSchema,
            onSubmit: (values, action) => {
                formSubmit(values, action);
            },
        });

        const handleFileChange = (event) => {
            const selectedFile = event.target.files[0];
            setFile(selectedFile);
            setFieldValue("image", selectedFile);
            setShowProgress(true);
    
            let simulatedProgress = 0;
            const interval = setInterval(() => {
                simulatedProgress += 10;
                setProgress(simulatedProgress);
                if (simulatedProgress >= 100) {
                    clearInterval(interval);
                    setShowProgress(false);
                }
            }, 200);
        };
    const formSubmit = (values, action) => {
        const uploadForm = async (values) => {

            try {
                const response = await GeneralService.addProduct(values, adminId);
                setMessage("Product inserted");
            } catch (err) {
                if (err?.response?.status == 422) {
                    // TODO
                    // server errors
                    if (err?.response?.data?.category) {
                        setCategoryError(err?.response?.data?.category[0]);
                    }

                    if (err?.response?.data?.name) {
                        setNameError(err?.response?.data?.name[0]);
                    }

                    if (err?.response?.data?.description) {
                        setDescError(err?.response?.data?.description[0]);
                    }

                    if (err?.response?.data?.sale_price) {
                        setSalePriceError(err?.response?.data?.price[0]);
                    }

                    if (err?.response?.data?.purchase_price) {
                        setPurchasePriceError(err?.response?.data?.purchase_price[0]);
                    }

                    if (err?.response?.data?.image) {
                        setImageError(err?.response?.data?.image[0]);
                    }
                    // if (err?.response?.data?.available) {
                    //     setAvailableError(err?.response?.data?.available[0]);
                    // }

                    // if (err?.response?.data?.scale) {
                    //     setScaleError(err?.response?.data?.scale[0]);
                    // }

                } else {
                    setMessage("Error inserting product");
                }
            }
        }

        uploadForm(values);
    }

    return (
        <>
            <div className="content">
                <Sidebar />
                <div className="container-fluid pt-4 px-4">
                    <div className="bg-light text-center rounded p-4">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <h6 className="mb-0">Add Product</h6>
                        </div>
                        {message && (
                            <div className="alert alert-success" role="alert">
                                {message}
                            </div>
                        )}
                        <form onSubmit={handleSubmit} noValidate>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="form-floating mb-3">
                                        <select
                                            name="category"
                                            id="category"
                                            className="form-control"
                                            value={values.category || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        >
                                            <option value="">Select Category</option>
                                            {
                                                categories.map(row => {

                                                    return <option key={row.id} value={row.id}>{row.name}</option>
                                                })
                                            }
                                        </select>
                                        <label htmlFor="category">Category</label>
                                    </div>
                                    {errors.category && touched.category ? (
                                        <p className="help is-danger">{errors.category}</p>
                                    ) : null}
                                    {categoryError && (
                                        <p className="help is-danger">{categoryError}</p>
                                    )}
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-floating mb-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            name="name"
                                            placeholder="Name"
                                            value={values.name || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <label htmlFor="name">Name</label>
                                    </div>
                                    {errors.name && touched.name ? (
                                        <p className="help is-danger">{errors.name}</p>
                                    ) : null}
                                    {nameError && (
                                        <p className="help is-danger">{nameError}</p>
                                    )}
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-floating mb-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="description"
                                            name="description"
                                            placeholder="Description"
                                            value={values.description || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <label htmlFor="description">Description</label>
                                    </div>
                                    {errors.description && touched.description ? (
                                        <p className="help is-danger">{errors.description}</p>
                                    ) : null}
                                    {descError && (
                                        <p className="help is-danger">{descError}</p>
                                    )}
                                </div>
                                <div className="col-md-6">
                                    <div className="file-upload mb-4">
                                        <label
                                            className={`file-label ${file ? 'file-selected' : ''}`}
                                        >
                                            <AiOutlineCloudUpload className="file-icon" />
                                            <span>{file ? file.name : 'Choose Image'}</span>
                                            <input
                                                type="file"
                                                name="image"
                                                id="image"
                                                className="file-input"
                                                onChange={handleFileChange}
                                                onBlur={handleBlur}
                                            />
                                        </label>
                                        {file && (
                                            <p className="file-counter animate__animated animate__fadeIn animate__scaleIn">
                                                Image Selected!
                                            </p>
                                        )}
                                    </div>

                                    {errors.image && touched.image ? (
                                        <p className="text-danger">{errors.image}</p>
                                    ) : null}

                                    {/* Progress Bar */}
                                    {showProgress && (
                                        <div className="progress-bar-container">
                                            <div className="progress-bar-wrapper">
                                                <div className="progress-bar" style={{ width: `${progress}%` }}></div>
                                                <div className="progress-text">{progress}%</div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-floating mb-4">
                                        <input
                                            type="text"
                                            id="purchase_price"
                                            name="purchase_price"
                                            className="form-control"
                                            placeholder="Purchase Price"
                                            value={values.purchase_price}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <label htmlFor="purchase_price">Purchase Price</label>
                                    </div>
                                    {errors.purchase_price && touched.purchase_price ? (
                                        <p className="help is-danger">{errors.purchase_price}</p>
                                    ) : null}
                                    {purchasePriceError && (
                                        <p className="help is-danger">{purchasePriceError}</p>
                                    )}
                                </div>
                                
                                <div className='col-md-6'>
                                    <div className="form-floating mb-4">
                                        <input
                                            type="text"
                                            id="sale_price"
                                            name="sale_price"
                                            className="form-control"
                                            placeholder="Sale Price"
                                            value={values.sale_price}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <label htmlFor="sale_price">Sale Price</label>
                                    </div>
                                    {errors.sale_price && touched.sale_price ? (
                                        <p className="help is-danger">{errors.sale_price}</p>
                                    ) : null}
                                    {salePriceError && (
                                        <p className="help is-danger">{salePriceError}</p>
                                    )}
                                </div>
                                
                                
                                {/* <div className='col-md-6'>
                                    <div className="form-floating mb-4">
                                        <select
                                            name="scale"
                                            id="scale"
                                            className="form-control"
                                            value={values.scale || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        >
                                            <option value="">Select Scale</option>
                                            <option value="kg">Kg</option>
                                            <option value="litre">Litre</option>
                                            <option value="bundle">Bundle</option>
                                        </select>
                                        <label htmlFor="scale">Scale</label>
                                    </div>
                                    {errors.scale && touched.scale ? (
                                        <p className="help is-danger">{errors.scale}</p>
                                    ) : null}
                                    {scaleError && (
                                        <p className="help is-danger">{scaleError}</p>
                                    )}
                                </div> */}
                                 <div className="col-md-12 d-flex">
                                    <button className="btn btn-submit" type="submit" disabled={isLoading}>
                                        {isLoading ? "Submitting..." : "Submit"}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <Footer />
            </div>
            <style jsx>{`
                .file-upload {
                    position: relative;
                    text-align: center;
                }
         
                .file-label {
                    display: inline-block;
                    padding: 12px 20px;
                    border: 2px dashed #f44c00;
                    border-radius: 10px;
                    color: #f44c00;
                    cursor: pointer;
                    transition: background-color 0.3s ease;
                    width: 100%;
                    text-align: center;
                }
                .file-label:hover {
                    background-color: rgba(255, 255, 255, 0.1);
                    border: 2px solid rgba(255, 255, 255, 0.5);
                }
                     .file-label.file-selected { 
                        border: 2px dashed #4CAF50; 
                        color: #4CAF50;  
                    }

                .file-icon {
                    font-size: 28px;
                    margin-right: 10px;
                }
                .file-input {
                    display: none;
                }
                .file-counter {
                    font-size: 14px;
                    margin-top: 8px;
                    color: #4CAF50;
                }

                .progress-bar-container {
                    width: 100%;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    margin-bottom: 5px;
                }

                .progress-bar-wrapper {
                    width: 100%;
                    background: rgba(0, 0, 0, 0.1);
                    border-radius: 10px;
                    overflow: hidden;
                    height: 8px;
                    position: relative;
                }

                .progress-bar {
                    height: 100%;
                    background: #f44c00;
                    transition: width 0.3s ease;
                }

                .progress-text {
                    position: absolute;
                    top: 100%;
                    width: 100%;
                    font-size: 14px;
                    color: #f44c00;
                    font-weight: bold;
                    text-align: center;
                    margin-top: 5px;
                }

                .btn-submit {
                    background-color: #f44c00;
                    border-color: #f44c00;
                    padding: 14px 28px;
                    font-size: 16px;
                    font-weight: 600;
                    text-transform: uppercase;
                    border-radius: 30px;
                    transition: background-color 0.3s, border-color 0.3s;
                    display: inline-block;
                    margin-top: 10px;
                    color: #fff
                }

                .btn-submit:hover {
                    background-color: #d43f00;
                    border-color: #d43f00;
                    color: #fff;
                }

                .btn-submit:focus {
                    outline: none;
                }
            `}</style>
        </>
    )
}