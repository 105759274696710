import React, { useEffect, useState } from 'react';
import veggieIcon from '../../Images/veggie-favicon.png';
import avatarIcon from '../../Images/avatar1.png';
import { Link, useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreaters } from '../../Redux';
import { FaSun, FaMoon, FaCloudSun, FaHandPaper } from 'react-icons/fa';

export default function Sidebar() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userActions = bindActionCreators(actionCreaters, dispatch);

    const [showWelcome, setShowWelcome] = useState(true);
    const [greeting, setGreeting] = useState('');
    
    // Correctly calculate current hour in Pakistan Standard Time (UTC+5)
    const currentHour = new Date(new Date().getTime() + 5 * 60 * 60 * 1000).getUTCHours();

    useEffect(() => {
        setTimeout(() => {
            setShowWelcome(false);
        }, 3000); // Show "Welcome User" for 3 seconds

        const iconStyle = {
            fontSize: '3rem',
            color: currentHour >= 5 && currentHour < 18 ? '#FFA500' : '#4A90E2', // Orange for morning/afternoon, blue for evening
            marginLeft: '15px',
            backgroundColor: 'rgba(255, 255, 255, 0.25)',
            padding: '5px',
            borderRadius: '50%',
            boxShadow: `0 6px 12px rgba(0, 0, 0, 0.2), 0 8px 24px ${
                currentHour >= 5 && currentHour < 18 ? 'rgba(255, 165, 0, 0.5)' : 'rgba(74, 144, 226, 0.5)'
            }`,
            backdropFilter: 'blur(10px)',
        };

        // Adjust greetings based on the specified time ranges
        if (currentHour >= 5 && currentHour < 12) {
            setGreeting(
                <span style={{ display: 'flex', alignItems: 'center', fontSize: '1.4rem', marginLeft: '20px' }}>
                    Good Morning <FaSun style={iconStyle} />
                </span>
            );
        } else if (currentHour >= 12 && currentHour < 18) {
            setGreeting(
                <span style={{ display: 'flex', alignItems: 'center', fontSize: '1.4rem', marginLeft: '20px' }}>
                    Good Afternoon <FaSun style={iconStyle} />
                </span>
            );
        } else {
            setGreeting(
                <span style={{ display: 'flex', alignItems: 'center', fontSize: '1.4rem', marginLeft: '20px' }}>
                    Good Evening <FaMoon style={{ ...iconStyle, color: '#4A90E2' }} />
                </span>
            );
        }
    }, [currentHour]);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    useEffect(() => {
        const contentElement = document.querySelector('.content');
        if (contentElement) {
            contentElement.style.marginLeft = sidebarOpen ? '0px' : '250px';
        }
    }, [sidebarOpen]);

    const handleDropdownClick = (e) => {
        e.stopPropagation();
    };

    const closeDropdown = (e) => {
        const dropdown = e.target.closest('.dropdown');
        if (dropdown) {
            dropdown.classList.remove('show');
            const menu = dropdown.querySelector('.dropdown-menu');
            if (menu) menu.classList.remove('show');
        }
    };

    const logOut = async (event) => {
        event.preventDefault();
        secureLocalStorage.clear();
        userActions.logOut(null);
        window.location.href = '/';
    };

    return (
        <>
          <div className={`sidebar pe-4 pb-3 glass-effect ${sidebarOpen ? 'open' : ''}`}>
    <nav className="navbar glass-effect bg-light navbar-light">
        {/* <!-- Logo and Profile Section --> */}
        <Link to="/" className="navbar-brand mx-4 mb-3">
            <h3 className="text-primary glass-title">
                <img src={veggieIcon} style={{ width: "30px", marginRight: "5px" }} alt="" />VEGGIE KING
            </h3>
        </Link>
        {/* <!-- Profile Image --> */}
        <div className="d-flex align-items-center ms-4 mb-4">
            <div className="position-relative">
                <img className="rounded-circle glass-avatar" src={avatarIcon} alt="" style={{ width: "50px", height: "50px" }} />
                <div className="status-indicator bg-success rounded-circle border border-2 border-white position-absolute end-0 bottom-0 p-1"></div>
            </div>
            <div className="ms-3">
                <h6 className="mb-0">Veggie King</h6>
                <span className="admin">Admin</span>
            </div>
        </div>
        {/* <!-- Navigation Links --> */}
        <div className="navbar-nav w-100">
                        <Link to="/dashboard" className="nav-item nav-link active"><i className="fa fa-home me-2"></i>Dashboard</Link>
                        <div className="nav-item dropdown">
                            <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown"><i className="fa fa-th-list me-2"></i>Categories</a>
                            <div className="dropdown-menu bg-transparent border-0" onClick={handleDropdownClick}>
                                <Link to="/add-category" className="dropdown-item" onClick={closeDropdown}>Add Category</Link>
                                <Link to="/view-categories" className="dropdown-item" onClick={closeDropdown}>List Categories</Link>
                            </div>
                        </div>
                        <div className="nav-item dropdown">
                            <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown"><i className="fa fa-box me-2"></i>Products</a>
                            <div className="dropdown-menu bg-transparent border-0" onClick={handleDropdownClick}>
                                <Link to="/add-products" className="dropdown-item" onClick={closeDropdown}>Add Product</Link>
                                <Link to="/view-products" className="dropdown-item" onClick={closeDropdown}>List Products</Link>
                            </div>
                        </div>
                        <div className="nav-item dropdown">
                            <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown"><i className="fa fa-shopping-cart me-2"></i>Orders</a>
                            <div className="dropdown-menu bg-transparent border-0" onClick={handleDropdownClick}>
                                <Link to="/view-orders" className="dropdown-item" onClick={closeDropdown}>List Orders</Link>
                                <Link to="/manual-orders" className="dropdown-item" onClick={closeDropdown}>Manual Orders</Link>
                            </div>
                        </div>

                        <div className="nav-item dropdown">
                            <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown"><i className="fa fa-motorcycle me-2"></i>Riders</a>
                            <div className="dropdown-menu bg-transparent border-0" onClick={handleDropdownClick}>
                                <Link to="/add-riders" className="dropdown-item" onClick={closeDropdown}>Add Riders</Link>
                                <Link to="/view-riders" className="dropdown-item" onClick={closeDropdown}>List Riders</Link>
                            </div>
                        </div>
                        <div className="nav-item dropdown">
                            <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown"><i className="fa fa-cogs me-2"></i>Management</a>
                            <div className="dropdown-menu bg-transparent border-0" onClick={handleDropdownClick}>
                                <Link to="/manage-announcement" className="dropdown-item" onClick={closeDropdown}>Manage Announcement</Link>
                                <Link to="/add-slider" className="dropdown-item" onClick={closeDropdown}>Add Slider</Link>
                                <Link to="/manage-sliders" className="dropdown-item" onClick={closeDropdown}>Manage Sliders</Link>
                                <Link to="/contact-messages" className="dropdown-item" onClick={closeDropdown}>Contact Messages</Link>
                                <Link to="/product-required" className="dropdown-item" onClick={closeDropdown}>Product Required</Link>
                                <Link to="/summary-report" className="dropdown-item" onClick={closeDropdown}>Summary Report</Link>
                                <Link to="/view-users" className="dropdown-item" onClick={closeDropdown}>View Users</Link>
                                <Link to="/delivery-charges" className="dropdown-item" onClick={closeDropdown}>Delivery Charges</Link>
                                <Link to="/min-charges" className="dropdown-item" onClick={closeDropdown}>Minimum Charges</Link>
                            </div>
                        </div>
                    </div>
    </nav>
</div>
<nav className="navbar navbar-expand bg-light navbar-light sticky-top px-4 py-0">
            <Link to="/" className="navbar-brand d-flex d-lg-none me-4">
                <h2 className="text-primary mb-0" style={{ backgroundColor: '#FAFAFA', borderRadius: '10%', padding: '5px' }}>
                    <img src={veggieIcon} style={{ width: "30px", borderRadius: '50%', marginRight: "5px" }} alt="Veggie Icon" />
                </h2>
            </Link>
            <a href="#" className="sidebar-toggler flex-shrink-0" onClick={toggleSidebar}>
                <i className="fa fa-bars"></i>
            </a>
            <h2 className="greeting-text" style={{
                      fontSize: '20px',
                      color: '#333',
                      fontWeight: '500',
                      animation: showWelcome ? 'fadeInOut 3s ease-in-out' : 'none'
                  }}>
                      {showWelcome ? (
                          <span style={{ display: 'flex', alignItems: 'center', fontSize: '1.2rem', marginLeft: '10px' }}>
                              Welcome Admin 
                              <FaHandPaper style={{
                                  fontSize: '3rem',
                                  marginLeft: '10px',
                                  padding: '5px',
                                  animation: 'wave 1s infinite',
                                  color: 'rgb(226 205 4)',
                                  WebkitBackgroundClip: 'text',
                                  WebkitTextFillColor: 'transparent',
                                  backgroundColor: 'rgba(255, 255, 255, 0.25)',
                                  boxShadow: `0 6px 12px rgba(255, 255, 0, 0.3), 0 8px 24px ${
                                      currentHour < 18 ? 'rgba(255, 223, 0, 0.5)' : 'rgba(255, 165, 0, 0.5)'
                                  }`,
                                  borderRadius: '50%',
                                  backdropFilter: 'blur(10px)',
                              }} />
                          </span>
                      ) : (
                          greeting
                      )}
                  </h2>
            <div className="navbar-nav align-items-center ms-auto">
                <div className="nav-item dropdown">
                    <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                        <img className="rounded-circle me-lg-2" src={avatarIcon} alt="Avatar"
                             style={{ width: "50px", height: "50px" }} />
                        <span className="d-none d-lg-inline-flex">Veggie King</span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-end bg-light border-0 rounded-0 rounded-bottom m-0">
                        <Link to="#" onClick={(e) => { logOut(e); }} className="dropdown-item">Log Out</Link>
                    </div>
                </div>
            </div>
        </nav>

        </>
    );
}
        