import { BrowserRouter, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Home from './Components/Home/Home';
import ViewProducts from './Components/Products/ViewProducts';
import SignIn from './Components/SignIn/SignIn';
import AddRiders from './Components/Riders/AddRiders';
import EditRiders from './Components/Riders/EditRider';
import ViewRiders from './Components/Riders/ViewRiders';
import AddProducts from './Components/Products/AddProducts';
import ViewOrders from './Components/Orders/ViewOrders';
import AddCategory from './Components/Categories/AddCategory';
import ViewCategories from './Components/Categories/ViewCategory';
import EditCategory from './Components/Categories/EditCategory';
import EditProduct from './Components/Products/EditProduct';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreaters } from './Redux';
import OrderDetail from './Components/Orders/OrderDetail';
import ViewUsers from './Components/Management/ViewUsers';
import DeliveryCharges from './DeliveryCharges';
import ProductRequired from './ProductRequired';
import TodaySale from './Components/Management/SummaryReport';
import SummaryReport from './Components/Management/SummaryReport';
import Loader from './Components/Includes/Loader';
import veggieIcon from './Images/veggie-favicon.png';
import ViewMessages from './Components/Management/ViewMessages';
import ManageNotification from './Components/Management/ManageNotification';
import ManageSliders from './Components/Management/ManageSliders';
import AddSlider from './Components/Management/AddSlider';
import EditUser from './Components/Management/EditUser';
import ViewUserOrders from './Components/Orders/ViewUserOrders';
import MinCharges from './MinCharges';
import ManualOrder from './Components/Orders/ManualOrder';
import DeliveredProduct from './DeliveredProduct';
import OnlineStatus from './Components/Includes/OnlineStatus';
import NotFound from './Components/Includes/NotFound';

function App() {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const state = useSelector((state) => state.stateVals);
  const { id } = state;

  const handleSignIn = () => {
    setAuthenticated(true);
  };

  const handleSignOut = () => {
    setAuthenticated(false);
  };

  useEffect(() => {
    // Simulate loading delay
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (id) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
  }, [id]);

  // Use localStorage to track if glitter has been shown
  useEffect(() => {
    if (authenticated) {
      // Check if glitter effect has been shown before
      const glitterShown = localStorage.getItem('glitterShown');
      if (!glitterShown) {
        // Create glitter particles only when authenticated and first login
        const container = document.createElement('div');
        container.className = 'glitter-container';

        for (let i = 0; i < 50; i++) {
          const glitter = document.createElement('div');
          glitter.className = 'glitter';
          glitter.style.left = `${Math.random() * 100}vw`;
          glitter.style.animationDuration = `${2 + Math.random() * 2}s`;
          glitter.style.animationDelay = `${Math.random() * 5}s`;
          container.appendChild(glitter);
        }

        document.body.appendChild(container);
        setTimeout(() => {
          container.remove();
        }, 5000); // Effect duration

        // Mark glitter as shown in localStorage
        localStorage.setItem('glitterShown', 'true');
      }
    }
  }, [authenticated]);

  return (
    <div className="App">
      <BrowserRouter>
      <OnlineStatus />
        {loading ? (
          <Loader />
        ) : (
          <Routes>
            <Route
              path="/"
              element={
                authenticated ? (
                  <Navigate to="/dashboard" replace />
                ) : (
                  <SignIn onSignIn={handleSignIn} authenticated={authenticated} />
                )
              }
            />
            <>
              <Route path="/dashboard" element={authenticated ? <Home onSignOut={handleSignOut} /> : <Navigate to="/" />} />
              <Route path="/manage-announcement" element={authenticated ? <ManageNotification /> : <Navigate to="/" />} />
              <Route path="/add-slider" element={authenticated ? <AddSlider /> : <Navigate to="/" />} />
              <Route path="/manage-sliders" element={authenticated ? <ManageSliders /> : <Navigate to="/" />} />
              <Route path="/contact-messages" element={authenticated ? <ViewMessages /> : <Navigate to="/" />} />
              <Route path="/add-products" element={authenticated ? <AddProducts /> : <Navigate to="/" />} />
              <Route path="/edit-product/:id" element={authenticated ? <EditProduct /> : <Navigate to="/" />} />
              <Route path="/add-category" element={authenticated ? <AddCategory /> : <Navigate to="/" />} />
              {/* <Route path="/delivery-charges" element={authenticated ? <DeliveryCharges /> : <Navigate to="/" />} /> */}
              <Route path="/min-charges" element={authenticated ? <MinCharges /> : <Navigate to="/" />} />
              <Route path="/edit-category/:id" element={authenticated ? <EditCategory /> : <Navigate to="/" />} />
              <Route path="/view-categories" element={authenticated ? <ViewCategories /> : <Navigate to="/" />} />
              <Route path="/view-products" element={authenticated ? <ViewProducts /> : <Navigate to="/" />} />
              <Route path="/delivery-charges" element={authenticated ? <DeliveryCharges /> : <Navigate to="/" />} />
              <Route path="/view-user-orders/:id" element={authenticated ? <ViewUserOrders /> : <Navigate to="/" />} />
              <Route path="/view-orders" element={authenticated ? <ViewOrders /> : <Navigate to="/" />} />
              <Route path="/manual-orders" element={authenticated ? <ManualOrder /> : <Navigate to="/" />} />
              <Route path="/view-pending-orders" element={authenticated ? <ViewOrders /> : <Navigate to="/" />} />
              <Route path="/view-packing-orders" element={authenticated ? <ViewOrders /> : <Navigate to="/" />} />
              <Route path="/view-transit-orders" element={authenticated ? <ViewOrders /> : <Navigate to="/" />} />
              <Route path="/view-delivered-orders" element={authenticated ? <ViewOrders /> : <Navigate to="/" />} />
              <Route path="/view-cancelled-orders" element={authenticated ? <ViewOrders /> : <Navigate to="/" />} />
              <Route path="/order-detail" element={authenticated ? <OrderDetail /> : <Navigate to="/" />} />
              <Route path="/add-riders" element={authenticated ? <AddRiders /> : <Navigate to="/" />} />
              <Route path="/view-riders" element={authenticated ? <ViewRiders /> : <Navigate to="/" />} />
              <Route path="/view-users" element={authenticated ? <ViewUsers /> : <Navigate to="/" />} />
              <Route path="/edit-user/:id" element={authenticated ? <EditUser /> : <Navigate to="/" />} />
              <Route path="/summary-report" element={authenticated ? <SummaryReport /> : <Navigate to="/" />} />
              <Route path="/product-required" element={authenticated ? <ProductRequired /> : <Navigate to="/" />} />
              <Route path="/delivered-product" element={authenticated ? <DeliveredProduct /> : <Navigate to="/" />} />
              <Route path="/edit-rider/:id" element={authenticated ? <EditRiders /> : <Navigate to="/" />} />
              <Route path="*" element={<NotFound />} /> 
            </>
          </Routes>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;
