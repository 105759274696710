import React, { useEffect, useState } from 'react'
import Sidebar from '../Includes/Sidebar'
import Footer from '../Includes/Footer'
import GeneralService from '../../services/general.service';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';

export default function ViewUsers() {
    const [tableData, setTableData] = useState([]);

    const state = useSelector((state) => state.stateVals);
    const { id: adminId } = state;

    const fetchData = async () => {
        try {
            const response = await GeneralService.listAllUsers();
            if (Array.isArray(response.data.response)) {
                setTableData(response.data.response);
                console.log(JSON.stringify(response.data.response));
            } else {
                console.error('Response data is not an array:', response.data.response);
            }
        } catch (error) {
            console.error('Error fetching table data:', error);
        }
    };

    const updateStatus = (id) => {
        const userStatus = async (id) => {
            try {
                const response = await GeneralService.userActiveDisabled(id, adminId);
                fetchData();
                // console.log(response);
                Swal.fire({
                    title: "User update",
                    text: "Status updated",
                    icon: "success",
                    confirmButtonText: "OK"
                });
            } catch (err) {
                console.log(err);
                Swal.fire({
                    title: "Error",
                    text: "An error occurred while updating status.",
                    icon: "error",
                    confirmButtonText: "OK"
                });
            }
        }

        userStatus(id);
    }

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <>
            <div className="content">
                <Sidebar />
                <div className="container-fluid pt-4 px-4">
                    <div className="bg-light text-center rounded p-4">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <h5 className="mb-0">Total Users <span className="number-rcords">({tableData.length})</span></h5>
                        </div>
                        <div className="table-wrapper">
                            <table className="table">
                                <thead>
                                    <tr className="text-dark">
                                        {/* <th scope="col"><input className="form-check-input" type="checkbox" /></th> */}
                                        <th>No</th>
                                        <th>Client Id</th>
                                        <th>Name</th>
                                        <th>Phone No</th>
                                        <th>Email</th>
                                        <th>Address</th>
                                        <th>Registeration Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.isArray(tableData) && tableData.map((item, index) => (
                                        <tr key={index + 1}>
                                            {/* <td><input className="form-check-input" type="checkbox" /></td> */}
                                            <td>{index + 1}</td>
                                            <td><Link to={`/view-user-orders/${item.id}`}>{item.unique_id}</Link></td>
                                            <td>{item.first_name + ' ' + item.last_name}</td>
                                            <td>{item.phone}</td>
                                            <td>{item.email}</td>
                                            <td>{item.address}</td>
                                            <td>{item.created_at}</td>
                                            <td><Link to={`/edit-user/${item.id}`}>Change Password</Link>{" "}
                                                <a className="btn btn-sm btn-primary" onClick={() => updateStatus(item.id)}
                                                    href="javascript:void(0)">{item.status == 'active' ? "Active" : "Disabled"}</a></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}
