import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="not-found-container">
        <div className="not-found">
          <div className="not-found-icon">
            <span role="img" aria-label="warning" className="warning-icon">⚠️</span>
          </div>
          <h1>404 - Page Not Found</h1>
          <button onClick={() => navigate(-1)}>Go Back</button>
        </div>
      </div>

      <style jsx>{`
        .not-found-container {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
          background: linear-gradient(135deg, rgba(72, 61, 139, 0.1), rgba(255, 255, 255, 0.2));
        }

        .not-found {
          text-align: center;
          padding: 40px 60px;
          background: rgba(255, 255, 255, 0.15);
          border-radius: 20px;
          backdrop-filter: blur(10px);
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          transition: all 0.3s ease-in-out;
          animation: fadeIn 1s ease-out;
        }

        .not-found-icon {
          font-size: 80px;
          animation: shake 1s ease-in-out infinite;
        }

        .not-found h1 {
          font-size: 3rem;
          color: #f44c00;
          margin: 20px 0;
        }

        .not-found button {
          background-color: #f44c00;
          border-color: #f44c00;
          padding: 14px 28px;
          font-size: 16px;
          font-weight: 600;
          text-transform: uppercase;
          border-radius: 30px;
          transition: background-color 0.3s, border-color 0.3s;
          display: inline-block;
          margin-bottom: 20px;
          color: #fff;
        }

        .not-found button:hover {
          background-color: #d43f00;
          border-color: #d43f00;
          color: #fff;
        }

        .not-found button:focus {
          outline: none;
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(-30px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes shake {
          0% {
            transform: translateX(-10px);
          }
          50% {
            transform: translateX(10px);
          }
          100% {
            transform: translateX(-10px);
          }
        }
      `}</style>
    </>
  );
};

export default NotFound;
