import React, { useEffect, useState } from 'react';
import Sidebar from './Components/Includes/Sidebar';
import Footer from './Components/Includes/Footer';
import GeneralService from './services/general.service';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const API_URL = 'https://api.veggieking.pk/public'; // Make sure to set the correct API URL

// Functions to handle price updates
const updateDeliveryPrice = (values, id, adminId) => {
    const formData = new FormData();
    formData.append('price', parseInt(values.price, 10));
    formData.append('updated_by', adminId);

    const headers = {
        "Content-Type": "multipart/form-data",
    };

    return axios.post(
        `${API_URL}/update-delivery-price/${id}`,
        formData,
        { headers: headers }
    );
};

const updateUpperLimit = (values, id, adminId) => {
    const formData = new FormData();
    formData.append('upper_limit', parseInt(values.upper_limit, 10));
    formData.append('updated_by', adminId);

    const headers = {
        "Content-Type": "multipart/form-data",
    };

    return axios.post(
        `${API_URL}/update-upper-limit/${id}`,
        formData,
        { headers: headers }
    );
};

const updateLowerLimit = (values, id, adminId) => {
    const formData = new FormData();
    formData.append('lower_limit', parseInt(values.lower_limit, 10));
    formData.append('updated_by', adminId);

    const headers = {
        "Content-Type": "multipart/form-data",
    };

    return axios.post(
        `${API_URL}/update-lower-limit/${id}`,
        formData,
        { headers: headers }
    );
};

export default function DeliveryCharges() {
    const [tableData, setTableData] = useState([]);
    const [editableData, setEditableData] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [editIndex2, setEditIndex2] = useState(null);
    const [editIndex3, setEditIndex3] = useState(null);
    const [editingField, setEditingField] = useState(null);
    const [editingField2, setEditingField2] = useState(null);
    const [editingField3, setEditingField3] = useState(null);
    const navigation = useNavigate();
    const state = useSelector((state) => state.stateVals);
    const { id: adminId } = state;

    const fetchData = async () => {
        try {
            const response = await GeneralService.listAllDeliveryCharges();
            if (Array.isArray(response.data.response)) {
                setTableData(response.data.response);
                setEditableData(response.data.response); // Initialize editableData
            } else {
                console.error('Response data is not an array:', response.data.response);
            }
        } catch (error) {
            console.error('Error fetching table data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleInputChange = (e, index, field) => {
        const newData = [...editableData];
        newData[index][field] = e.target.value;
        setEditableData(newData);
    };

    const handleEdit = (index, field) => {
        setEditIndex(index);
        setEditingField(field);
    };

    const handleEdit2 = (index, field) => {
        setEditIndex2(index);
        setEditingField2(field);
    };

    const handleEdit3 = (index, field) => {
        setEditIndex3(index);
        setEditingField3(field);
    };

    const handleSave = async (index, field) => {
        const itemToSave = editableData[index];
        try {
            if (field === 'price') {
                await updateDeliveryPrice(
                    { price: itemToSave.price },
                    itemToSave.id,
                    adminId
                );
            } else if (field === 'upper_limit') {
                await updateUpperLimit(
                    { upper_limit: itemToSave.upper_limit },
                    itemToSave.id,
                    adminId
                );
            } else if (field === 'lower_limit') {
                await updateLowerLimit(
                    { lower_limit: itemToSave.lower_limit },
                    itemToSave.id,
                    adminId
                );
            }

            fetchData();
            setEditIndex(null);
            setEditingField(null);
            Swal.fire({
                title: "Information update",
                text: "Information updated successfully.",
                icon: "success",
                confirmButtonText: "OK"
            });
        } catch (error) {
            console.error('Error saving data:', error);
            Swal.fire({
                title: "Error",
                text: "An error occurred while saving the data.",
                icon: "error",
                confirmButtonText: "OK"
            });
        }
    };

    const handleCancel = () => {
        setEditIndex(null);
        setEditingField(null);
        fetchData();
    };

    const handleCancel2 = () => {
        setEditIndex2(null);
        setEditingField2(null);
        fetchData();
    };

    const handleCancel3 = () => {
        setEditIndex3(null);
        setEditingField3(null);
        fetchData();
    };

    return (
        <div className="content">
            <Sidebar />

            <div className="container-fluid pt-4 px-4">
                <div className="bg-light text-center rounded p-4">
                    <div className="d-flex align-items-center justify-content-between mb-4">
                        <h6 className="mb-0">Delivery Charges <span className="number-rcords">({tableData.length})</span></h6>
                    </div>
                    <div className="table-wrapper">
                        <table className="table">
                            <thead>
                                <tr className="text-dark">
                                    <th>No.</th>
                                    <th>Lower Limit</th>
                                    <th>Upper Limit</th>
                                    <th>Charges</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(editableData) && editableData.map((item, index) => (
                                    <tr key={index + 1}>
                                        <td>{index + 1}</td>
                                        <td>
                                            <div className="input-group" style={{ display: 'inline' }}>
                                                <input
                                                    type="text"
                                                    value={item.lower_limit}
                                                    onChange={(e) => handleInputChange(e, index, 'lower_limit')}
                                                    className={`price-input ${editIndex2 === index && editingField2 === 'lower_limit' ? 'editable' : ''}`}
                                                    disabled={editIndex2 !== index || editingField2 !== 'lower_limit'}
                                                />
                                                {editIndex2 === index && editingField2 === 'lower_limit' ? (
                                                    <>
                                                        <a style={{ margin: 10 }} href='javascript:void(0)' onClick={() => handleSave(index, 'lower_limit')}><i className="fa fa-check" aria-hidden="true"></i></a>
                                                        <a href='javascript:void(0)' onClick={handleCancel2}><i className="fa fa-times" aria-hidden="true"></i></a>
                                                    </>
                                                ) : (
                                                    <a href='javascript:void(0)' onClick={() => handleEdit2(index, 'lower_limit')}><i className="fa fa-edit" aria-hidden="true"></i></a>
                                                )}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="input-group" style={{ display: 'inline' }}>
                                                <input
                                                    type="text"
                                                    value={item.upper_limit}
                                                    onChange={(e) => handleInputChange(e, index, 'upper_limit')}
                                                    className={`price-input ${editIndex3 === index && editingField3 === 'upper_limit' ? 'editable' : ''}`}
                                                    disabled={editIndex3 !== index || editingField3 !== 'upper_limit'}
                                                />
                                                {editIndex3 === index && editingField3 === 'upper_limit' ? (
                                                    <>
                                                        <a style={{ margin: 10 }} href='javascript:void(0)' onClick={() => handleSave(index, 'upper_limit')}><i className="fa fa-check" aria-hidden="true"></i></a>
                                                        <a href='javascript:void(0)' onClick={handleCancel3}><i className="fa fa-times" aria-hidden="true"></i></a>
                                                    </>
                                                ) : (
                                                    <a href='javascript:void(0)' onClick={() => handleEdit3(index, 'upper_limit')}><i className="fa fa-edit" aria-hidden="true"></i></a>
                                                )}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="input-group" style={{ display: 'inline' }}>
                                                <input
                                                    type="text"
                                                    value={item.price}
                                                    onChange={(e) => handleInputChange(e, index, 'price')}
                                                    className={`price-input ${editIndex === index && editingField === 'price' ? 'editable' : ''}`}
                                                    disabled={editIndex !== index || editingField !== 'price'}
                                                />
                                                {editIndex === index && editingField === 'price' ? (
                                                    <>
                                                        <a style={{ margin: 10 }} href='javascript:void(0)' onClick={() => handleSave(index, 'price')}><i className="fa fa-check" aria-hidden="true"></i></a>
                                                        <a href='javascript:void(0)' onClick={handleCancel}><i className="fa fa-times" aria-hidden="true"></i></a>
                                                    </>
                                                ) : (
                                                    <a href='javascript:void(0)' onClick={() => handleEdit(index, 'price')}><i className="fa fa-edit" aria-hidden="true"></i></a>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}
