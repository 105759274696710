import React, { useEffect, useRef, useState } from 'react';
import Sidebar from './Components/Includes/Sidebar';
import Footer from './Components/Includes/Footer';
import GeneralService from './services/general.service';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FaCalendarAlt, FaPrint } from 'react-icons/fa';

export default function ProductRequired() {
    const dateInputRef = useRef(null);
    const [tableData, setTableData] = useState([]);
    const [isPrinting, setIsPrinting] = useState(false);

    const handleDateChange = () => {
        if (dateInputRef.current) {
            fetchData(dateInputRef.current.value);
        }
    };

    const fetchData = async (date) => {
        try {
            const response = await GeneralService.productsRequired(date);
            console.log(response.data.response);
            if (Array.isArray(response.data.response)) {
                setTableData(response.data.response);
                console.log(JSON.stringify(response.data.response));
            } else {
                setTableData([]);
                console.error('Response data is not an array:', response.data.response);
            }
        } catch (error) {
            setTableData([]);
            console.error('Error fetching table data:', error);
        }
    };

    const handlePrint = () => {
        setIsPrinting(true);
        setTimeout(() => {
            const printContents = document.getElementById('printableArea').innerHTML;
            const originalContents = document.body.innerHTML;
            document.body.innerHTML = printContents;
            window.print();
            document.body.innerHTML = originalContents;
            setIsPrinting(false);
        }, 300); // Short delay for animation effect
    };

    return (
        <>
            <div className="content">
                <Sidebar />
                <div className="container-fluid pt-4 px-4">
                    <div className="bg-light text-center rounded p-4">
                        <div id="printableArea">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <h6 className="mb-0">Product Required <span className="number-records">({tableData.length})</span></h6>
                                <div className="date-picker-container">
                                    <FaCalendarAlt className="calendar-icon" />
                                    <input
                                        type="date"
                                        ref={dateInputRef}
                                        onChange={handleDateChange}
                                        className="date-input"
                                    />
                                </div>
                                <button
                                    className={`btn btn-secondary ${isPrinting ? 'printing' : ''}`}
                                    onClick={handlePrint}
                                >
                                    <FaPrint className="print-icon" /> Print
                                </button>
                            </div>
                            <span>For Pending Orders</span>
                            <div className="table-wrapper">
                                <table className="table">
                                    <thead>
                                        <tr className="text-dark">
                                            <th scope="col" style={{ width: "10%" }}>No</th>
                                            <th className="print-hidden" scope="col" style={{ width: "20%" }}>Image</th>
                                            <th scope="col" style={{ width: "50%" }}>Name</th>
                                            <th scope="col" style={{ width: "20%" }}>Quantity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(tableData) && tableData.map((item, index) => (
                                            <tr key={index + 1}>
                                                <td>{index + 1}</td>
                                                <td className="print-hidden"><img src={`https://api.veggieking.pk/public/upload/${item.image}`} width="50%" alt="product" /></td>
                                                <td>{item.name}</td>
                                                <td>{item.quantity}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            <style jsx>{`
                .date-picker-container {
                    position: relative;
                    display: inline-block;
                }
                .date-input {
                    padding: 8px 12px;
                    padding-left: 40px;
                    font-size: 16px;
                    border: 1px solid #ddd;
                    border-radius: 30px;
                    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
                    transition: border-color 0.3s ease;
                    outline: none;
                }
                .date-input:hover,
                .date-input:focus {
                    border-color: #f44c00;
                }
                .calendar-icon {
                    position: absolute;
                    top: 50%;
                    left: 12px;
                    transform: translateY(-50%);
                    color: #f44c00;
                    font-size: 18px;
                }
                .btn-secondary {
                    background-color: #f44c00;
                    border-color: #f44c00;
                    padding: 12px 24px;
                    font-size: 16px;
                    font-weight: 600;
                    text-transform: uppercase;
                    border-radius: 30px;
                    color: #fff;
                    display: inline-flex;
                    align-items: center;
                    transition: background-color 0.3s, border-color 0.3s;
                    cursor: pointer;
                }
                .btn-secondary .print-icon {
                    margin-right: 8px;
                }
                .btn-secondary.printing {
                    animation: pop 0.3s ease;
                }
                .btn-secondary:hover {
                    background-color: #d43f00;
                    border-color: #d43f00;
                }
                .btn-secondary:focus {
                    outline: none;
                }
                
                @keyframes pop {
                    0% {
                        transform: scale(1);
                    }
                    50% {
                        transform: scale(1.1);
                    }
                    100% {
                        transform: scale(1);
                    }
                }
            `}</style>
        </>
    );
}
