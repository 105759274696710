import React, { useEffect, useState } from 'react';
import Sidebar from '../Includes/Sidebar';
import Footer from '../Includes/Footer';
import GeneralService from '../../services/general.service';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './ViewProduct.css';
import axios from 'axios';

const API_URL = 'https://api.veggieking.pk/public'; // Make sure to set the correct API URL

const updatePurchasePrice = (values, id, adminId) => {
    const formData = new FormData();
    formData.append('purchase_price', parseInt(values.purchase_price, 10));
    formData.append('updated_by', adminId);

    const headers = {
        "Content-Type": "multipart/form-data",
    };

    return axios.post(
        `${API_URL}/update-purchase-price/${id}`,
        formData,
        {
            headers: headers,
        }
    )
        .then(response => {
            console.log('Add product response:', response.data);
            return response.data;
        })
        .catch(error => {
            console.error('Error adding product:', error);
            throw error; // Rethrow the error to handle it in the calling component
        });
};

const updateSalePrice = (values, id, adminId) => {
    const formData = new FormData();
    formData.append('price', parseInt(values.price, 10));
    formData.append('updated_by', adminId);

    const headers = {
        "Content-Type": "multipart/form-data",
    };

    return axios.post(
        `${API_URL}/update-sale-price/${id}`,
        formData,
        {
            headers: headers,
        }
    );
};

const updateMaxQty = (values, id, adminId) => {
    const formData = new FormData();
    formData.append('max_qty', parseInt(values.max_qty, 10));
    formData.append('updated_by', adminId);

    const headers = {
        "Content-Type": "multipart/form-data",
    };

    return axios.post(
        `${API_URL}/update-max-qty/${id}`,
        formData,
        {
            headers: headers,
        }
    );
};

const updateAvailableStock = (values, id, adminId) => {
    const formData = new FormData();
    formData.append('stock_available', parseInt(values.stock_available, 10));
    formData.append('updated_by', adminId);

    const headers = {
        "Content-Type": "multipart/form-data",
    };

    return axios.post(
        `${API_URL}/update-available-stock/${id}`,
        formData,
        {
            headers: headers,
        }
    );
};

export default function ViewProducts() {
    const [tableData, setTableData] = useState([]);
    const [editableData, setEditableData] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [editIndex2, setEditIndex2] = useState(null);
    const [editIndex3, setEditIndex3] = useState(null);
    const [editIndex4, setEditIndex4] = useState(null);
    const [editingField, setEditingField] = useState(null);
    const [editingField2, setEditingField2] = useState(null);
    const [editingField3, setEditingField3] = useState(null);
    const [editingField4, setEditingField4] = useState(null);

    const state = useSelector((state) => state.stateVals);
    const { id: adminId } = state;

    const fetchData = async () => {
        try {
            const response = await GeneralService.listAllProducts();
            if (Array.isArray(response.data.response)) {
                setTableData(response.data.response);
                setEditableData(response.data.response); // Initialize editableData
                console.log(JSON.stringify(response.data.response));
            } else {
                console.error('Response data is not an array:', response.data.response);
            }
        } catch (error) {
            console.error('Error fetching table data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const remove = (id) => {
        const deleteData = async (id) => {
            try {
                await GeneralService.deleteProduct(id, adminId);
                fetchData();
            } catch (err) {
                console.log(err);
            }
        };

        Swal.fire({
            title: "Confirmation",
            text: "Are you sure you want to delete this product?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                deleteData(id);
            }
        });
    };

    const updateStatus = (id) => {
        const productStatus = async (id) => {
            try {
                await GeneralService.productHideUnhide(id, adminId);
                fetchData();
                Swal.fire({
                    title: "Product update",
                    text: "Status updated",
                    icon: "success",
                    confirmButtonText: "OK"
                });
            } catch (err) {
                console.log(err);
                Swal.fire({
                    title: "Error",
                    text: "An error occurred while updating status.",
                    icon: "error",
                    confirmButtonText: "OK"
                });
            }
        };

        productStatus(id);
    };

    const updateStockStatus = (id) => {
        const productStatus = async (id) => {
            try {
                await GeneralService.productStockStatus(id, adminId);
                fetchData();
                Swal.fire({
                    title: "Product update",
                    text: "Status updated",
                    icon: "success",
                    confirmButtonText: "OK"
                });
            } catch (err) {
                console.log(err);
                Swal.fire({
                    title: "Error",
                    text: "An error occurred while updating status.",
                    icon: "error",
                    confirmButtonText: "OK"
                });
            }
        };

        productStatus(id);
    };

    const handleInputChange = (e, index, field) => {
        const newData = [...editableData];
        newData[index][field] = e.target.value;
        setEditableData(newData);
    };

    const handleEdit = (index, field) => {
        setEditIndex(index);
        setEditingField(field);
    };

    const handleEdit2 = (index, field) => {
        setEditIndex2(index);
        setEditingField2(field);
    };

    const handleEdit3 = (index, field) => {
        setEditIndex3(index);
        setEditingField3(field);
    };

    const handleEdit4 = (index, field) => {
        setEditIndex4(index);
        setEditingField4(field);
    };


    const handleSave = async (index, field) => {
        const itemToSave = editableData[index];
        try {
            if (field === 'purchase_price') {
                await updatePurchasePrice(
                    { purchase_price: itemToSave.purchase_price },
                    itemToSave.id,
                    adminId
                );
            } else if (field === 'price') {
                await updateSalePrice(
                    { price: itemToSave.price },
                    itemToSave.id,
                    adminId
                );
            } else if (field === 'max_qty') {
                await updateMaxQty(
                    { max_qty: itemToSave.max_qty },
                    itemToSave.id,
                    adminId
                );
            } else if (field === 'stock_available') {
                await updateAvailableStock(
                    { stock_available: itemToSave.stock_available },
                    itemToSave.id,
                    adminId
                );
            }
            fetchData();
            setEditIndex(null);
            setEditingField(null);
            Swal.fire({
                title: "Product update",
                text: "Information updated successfully.",
                icon: "success",
                confirmButtonText: "OK"
            });
        } catch (error) {
            console.error('Error saving data:', error);
            Swal.fire({
                title: "Error",
                text: "An error occurred while saving prices.",
                icon: "error",
                confirmButtonText: "OK"
            });
        }
    };

    const handleCancel = () => {
        setEditIndex(null);
        setEditingField(null);
        fetchData(); // Re-fetch data to discard changes
    };

    const handleCancel2 = () => {
        setEditIndex2(null);
        setEditingField2(null);
        fetchData(); // Re-fetch data to discard changes
    };

    const handleCancel3 = () => {
        setEditIndex3(null);
        setEditingField3(null);
        fetchData(); // Re-fetch data to discard changes
    };

    const handleCancel4 = () => {
        setEditIndex4(null);
        setEditingField4(null);
        fetchData(); // Re-fetch data to discard changes
    };


    return (
        <>
            <div className="content">
                <Sidebar />

                <div className="container-fluid pt-4 px-4">
                    <div className="bg-light text-center rounded p-4">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <h5 className="mb-0">Total Products <span className="number-rcords">({tableData.length})</span></h5>
                        </div>
                        <div className="table-wrapper">
                            <table className="table">
                                <thead>
                                    <tr className="text-dark">
                                        <th>No.</th>
                                        <th>Category</th>
                                        <th>Product Image</th>
                                        <th>Name</th>
                                        <th>Purchase Price</th>
                                        <th>Sale Price</th>
                                        <th>Max Quantity</th>
                                        <th>Available Stock</th>
                                        <th>Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.isArray(editableData) && editableData.map((item, index) => (
                                        <tr key={index + 1}>
                                            <td>{index + 1}</td>
                                            <td>{item.catName}</td>
                                            <td style={{ width: "20%" }}>
                                                <img src={`https://api.veggieking.pk/public/upload/${item.image}`}
                                                    alt="Product Image" style={{ width: '100px', height: '100px' }} />
                                            </td>
                                            <td>{item.name}</td>
                                            <td>
                                                <div className="input-group" style={{ display: 'inline' }}>
                                                    <input
                                                        type="text"
                                                        value={item.purchase_price}
                                                        onChange={(e) => handleInputChange(e, index, 'purchase_price')}
                                                        className={`price-input ${editIndex === index && editingField === 'purchase_price' ? 'editable' : ''}`}
                                                        disabled={editIndex !== index || editingField !== 'purchase_price'}
                                                    />
                                                    {editIndex === index && editingField === 'purchase_price' ? (
                                                        <>
                                                            <a style={{ margin: 10 }} href='javascript:void(0)' onClick={() => handleSave(index, 'purchase_price')}><i class="fa fa-check" aria-hidden="true"></i></a>
                                                            <a href='javascript:void(0)' onClick={handleCancel}><i class="fa fa-times" aria-hidden="true"></i></a>
                                                        </>
                                                    ) : (
                                                        <a href='javascript:void(0)' onClick={() => handleEdit(index, 'purchase_price')}><i class="fa fa-edit" aria-hidden="true"></i></a>
                                                    )}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="input-group" style={{ display: 'inline' }}>
                                                    <input
                                                        type="text"
                                                        value={item.price}
                                                        onChange={(e) => handleInputChange(e, index, 'price')}
                                                        className={`price-input ${editIndex2 === index && editingField2 === 'price' ? 'editable' : ''}`}
                                                        disabled={editIndex2 !== index || editingField2 !== 'price'}
                                                    />
                                                    {editIndex2 === index && editingField2 === 'price' ? (
                                                        <>

                                                            <a style={{ margin: 10 }} href='javascript:void(0)' onClick={() => handleSave(index, 'price')}><i class="fa fa-check" aria-hidden="true"></i></a>
                                                            <a href='javascript:void(0)' onClick={handleCancel2}><i class="fa fa-times" aria-hidden="true"></i></a>
                                                        </>
                                                    ) : (
                                                        <a href='javascript:void(0)' onClick={() => handleEdit2(index, 'price')}><i class="fa fa-edit" aria-hidden="true"></i></a>
                                                    )}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="input-group" style={{ display: 'inline' }}>
                                                    <input
                                                        type="text"
                                                        value={item.max_qty}
                                                        onChange={(e) => handleInputChange(e, index, 'max_qty')}
                                                        className={`price-input ${editIndex3 === index && editingField3 === 'max_qty' ? 'editable' : ''}`}
                                                        disabled={editIndex3 !== index || editingField3 !== 'max_qty'}
                                                    />
                                                    {editIndex3 === index && editingField3 === 'max_qty' ? (
                                                        <>

                                                            <a style={{ margin: 10 }} href='javascript:void(0)' onClick={() => handleSave(index, 'max_qty')}><i class="fa fa-check" aria-hidden="true"></i></a>
                                                            <a href='javascript:void(0)' onClick={handleCancel3}><i class="fa fa-times" aria-hidden="true"></i></a>
                                                        </>
                                                    ) : (
                                                        <a href='javascript:void(0)' onClick={() => handleEdit3(index, 'max_qty')}><i class="fa fa-edit" aria-hidden="true"></i></a>
                                                    )}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="input-group" style={{ display: 'inline' }}>
                                                    <input
                                                        type="text"
                                                        value={item.stock_available}
                                                        onChange={(e) => handleInputChange(e, index, 'stock_available')}
                                                        className={`price-input ${editIndex4 === index && editingField4 === 'stock_available' ? 'editable' : ''}`}
                                                        disabled={editIndex4 !== index || editingField4 !== 'stock_available'}
                                                    />
                                                    {editIndex4 === index && editingField4 === 'stock_available' ? (
                                                        <>

                                                            <a style={{ margin: 10 }} href='javascript:void(0)' onClick={() => handleSave(index, 'stock_available')}><i class="fa fa-check" aria-hidden="true"></i></a>
                                                            <a href='javascript:void(0)' onClick={handleCancel4}><i class="fa fa-times" aria-hidden="true"></i></a>
                                                        </>
                                                    ) : (
                                                        <a href='javascript:void(0)' onClick={() => handleEdit4(index, 'stock_available')}><i class="fa fa-edit" aria-hidden="true"></i></a>
                                                    )}
                                                </div>
                                            </td>
                                            <td>{item.created_at}</td>
                                            <td className="action-buttons">
                                                <div className="button-group">
                                                    <Link className="btn btn-primary" to={`/edit-product/${item.id}`}>Edit</Link>
                                                    <button className="btn btn-danger" onClick={() => remove(item.id)}>Delete</button>
                                                    <button className={item.status === 'active' ? "btn" : "btn-new"} onClick={() => updateStatus(item.id)}>
                                                        {item.status === 'active' ? "Hide" : "Unhide"}
                                                    </button>
                                                    {/* <button className={item.stock === 'in' ? "btn" : "btn-new"} onClick={() => updateStockStatus(item.id)}>
                                                        {item.stock === 'in' ? "Out of Stock" : "In Stock"}
                                                    </button> */}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}
