import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../Includes/Sidebar";
import Footer from "../Includes/Footer";
import GeneralService from "../../services/general.service";
import swal from "sweetalert2";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaCalendarAlt, FaPrint ,FaEnvelopeOpen, FaEnvelope, FaList, FaClock, FaBoxOpen, FaTruck, FaCheckCircle, FaBan } from "react-icons/fa";

export default function ViewOrders() {
  const dateInputRef = useRef(null);
  const [tableData, setTableData] = useState([]);
  const [rows, setRows] = useState(tableData);
  const state = useSelector((state) => state.stateVals);
  const { id: adminId } = state;

  const [activeButton, setActiveButton] = useState("");

  const handleButtonClick = (buttonType) => {
    // alert(dateInputRef.current.value);
    setActiveButton(buttonType);
    fetchData(buttonType, dateInputRef.current.value);
  };

  const handleDateChange = () => {
    if (dateInputRef.current) {
      fetchData(activeButton, dateInputRef.current.value);
    }
  };

  const toggleReadStatus = (id, oldStatus) => {
    // const newStatus = oldStatus === 'old' ? 'new' : 'old';
    // setRows(rows.map(row =>
    //     row.id === id ? { ...row, order_status: newStatus } : row
    // ));

    const readStatus = async (id, oldStatus) => {
      if (oldStatus == "old") {
        const response = await GeneralService.updateOrderReadStatus(id, "new");
      } else {
        const response = await GeneralService.updateOrderReadStatus(id, "old");
      }
    };
    readStatus(id, oldStatus);
    // setRows(rows.map(row =>
    //     row.id === id ? { ...row, order_status: !row.order_status } : row
    // ));
    // console.log(rows);

    if (dateInputRef.current) {
      fetchData(activeButton, dateInputRef.current.value);
    }
  };

  const fetchData = async (type, date) => {
    try {
      if (!date) {
        date = "all";
      }
      const response = await GeneralService.listAllOrdersType(type, date);
      if (Array.isArray(response.data.response)) {
        setTableData(response.data.response);
        console.log(JSON.stringify(response.data.response));
      } else {
        setTableData([]);
        console.error("Response data is not an array:", response.data.response);
      }
    } catch (error) {
      setTableData([]);
      console.error("Error fetching table data:", error);
    }
  };

  // useEffect(() => {
  //     fetchData();
  // }, []);

  const assignOrder = (riderId, orderId) => {
    const assigningOrder = async (riderId, orderId) => {
      const response = await GeneralService.assignOrderToRider(
        riderId,
        orderId,
        adminId
      );
      if (dateInputRef.current) {
        fetchData(activeButton, dateInputRef.current.value);
      }
      console.log(response);
    };

    assigningOrder(riderId, orderId);
  };

  const statusUpdate = (id, oldStatus) => {
    const orderStatus = async (id, oldStatus) => {
      try {
        if (oldStatus == "cancelled") {
          const response = await GeneralService.updateOrderStatus(
            id,
            "pending"
          );
          if (dateInputRef.current) {
            fetchData(activeButton, dateInputRef.current.value);
          }
          // console.log(response);
          Swal.fire({
            title: "Order update",
            text: "Status updated to pending",
            icon: "success",
            confirmButtonText: "OK",
          });
        } else if (oldStatus == "pending") {
          const response = await GeneralService.updateOrderStatus(
            id,
            "packing"
          );
          if (dateInputRef.current) {
            fetchData(activeButton, dateInputRef.current.value);
          }
          // console.log(response);
          Swal.fire({
            title: "Order update",
            text: "Status updated to packed",
            icon: "success",
            confirmButtonText: "OK",
          });
        } else if (oldStatus == "packing") {
          const riderResponse = await GeneralService.listAllRiders();
          const riders = riderResponse.data.response;

          const riderOptions = riders.map((rider) => ({
            value: rider.id,
            text: rider.first_name + " " + rider.last_name,
          }));

          await Swal.fire({
            title: "Rider Assigning",
            text: "Select Rider",
            icon: "warning",
            showCancelButton: true,
            showConfirmButton: true,
            allowOutsideClick: false,
            html: `<select id="riderSelect" className="swal2-select" placeholder="Select a rider">${riderOptions
              .map(
                (option) =>
                  `<option value="${option.value}">${option.text}</option>`
              )
              .join("")}</select>`,
            confirmButtonText: "OK",
          }).then((result) => {
            const selectedRiderId =
              document.getElementById("riderSelect").value;
            console.log("Selected rider ID:", selectedRiderId);
            if (result.isConfirmed) {
              assignOrder(selectedRiderId, id);

              Swal.fire({
                title: "Rider assigned",
                text: "Order assigned to rider",
                icon: "success",
                confirmButtonText: "OK",
              });
            }
          });
        } else if (oldStatus === undefined) {
          const response = await GeneralService.updateOrderStatus(
            id,
            "cancelled"
          );
          if (dateInputRef.current) {
            fetchData(activeButton, dateInputRef.current.value);
          }
          // console.log(response);
          Swal.fire({
            title: "Order update",
            text: "Status updated to cancelled",
            icon: "success",
            confirmButtonText: "OK",
          });
        }
      } catch (err) {
        console.log(err);
        Swal.fire({
          title: "Error",
          text: "An error occurred while assigning the order.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    orderStatus(id, oldStatus);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "orange";
      case "packing":
      case "transit":
        return "blue";
      case "delivered":
        return "green";
      case "cancelled":
        return "red";
      default:
        return "black";
    }
  };

  function getStatusColorClass(status) {
    switch (status) {
      case "pending":
        return "status-pending";
      case "delivered":
        return "status-delivered";
      case "transit":
        return "status-transit";
      default:
        return "";
    }
  }

  return (
    <>
      <div className="content">
        <Sidebar />

        <div className="container-fluid pt-4 px-4">
          <div className="bg-light text-center rounded p-4">
            {/* <Link to="/view-pending-orders" className="btn btn-orange">Pending Orders</Link>
                                <Link to="/view-packing-orders" className="btn btn-orange">Packing Orders</Link>
                                <Link to="/view-transit-orders" className="btn btn-orange">Transit Orders</Link>
                                <Link to="/view-delivered-orders" className="btn btn-orange">Delivered Orders</Link>
                                <Link to="/view-cancelled-orders" className="btn btn-orange">Cancelled Orders</Link> */}
           <div className="button-group">
  <Link
    to="/view-orders"
    className={`btn btn-orange ${activeButton === "read" ? "active" : ""}`}
    onClick={() => handleButtonClick("read")}
  >
    <FaEnvelopeOpen /> Read Orders
  </Link>
  <Link
    to="/view-orders"
    className={`btn btn-orange ${activeButton === "unread" ? "active" : ""}`}
    onClick={() => handleButtonClick("unread")}
  >
    <FaEnvelope /> Unread Orders
  </Link>
  <Link
    to="/view-orders"
    className={`btn btn-orange ${activeButton === "all" ? "active" : ""}`}
    onClick={() => handleButtonClick("all")}
  >
    <FaList /> All Orders
  </Link>
  <Link
    to="/view-pending-orders"
    className={`btn btn-orange ${activeButton === "pending" ? "active" : ""}`}
    onClick={() => handleButtonClick("pending")}
  >
    <FaClock /> Pending Orders
  </Link>
  <Link
    to="/view-packing-orders"
    className={`btn btn-orange ${activeButton === "packing" ? "active" : ""}`}
    onClick={() => handleButtonClick("packing")}
  >
    <FaBoxOpen /> Packing Orders
  </Link>
  <Link
    to="/view-transit-orders"
    className={`btn btn-orange ${activeButton === "transit" ? "active" : ""}`}
    onClick={() => handleButtonClick("transit")}
  >
    <FaTruck /> Transit Orders
  </Link>
  <Link
    to="/view-delivered-orders"
    className={`btn btn-orange ${activeButton === "delivered" ? "active" : ""}`}
    onClick={() => handleButtonClick("delivered")}
  >
    <FaCheckCircle /> Delivered Orders
  </Link>
  <Link
    to="/view-cancelled-orders"
    className={`btn btn-orange ${activeButton === "cancelled" ? "active" : ""}`}
    onClick={() => handleButtonClick("cancelled")}
  >
    <FaBan /> Cancelled Orders
  </Link>
</div>

            <div className="d-flex align-items-center justify-content-between mb-4">
              <h5 className="mb-0">
                Total Orders{" "}
                <span className="number-rcords">({tableData.length})</span>
              </h5>
              <div className="date-picker-container">
                <FaCalendarAlt className="calendar-icon" />
                <input
                  type="date"
                  ref={dateInputRef}
                  onChange={handleDateChange}
                  className="date-input"
                />
              </div>
            </div>
            <div className="table-wrapper">
              <table className="table">
                <thead>
                  <tr className="text-dark">
                    {/* <th scope="col"><input className="form-check-input" type="checkbox" /></th> */}
                    <th>Show</th>
                    <th>No.</th>
                    <th>Invoice</th>
                    <th>Bill</th>
                    <th>Rider</th>
                    <th>Client Id</th>
                    <th>Name</th>
                    <th>Phone</th>
                    {/* <th>Email</th> */}
                    <th>Delivery Address</th>
                    <th>Date</th>
                    {/* <th>Instruction</th> */}
                    <th>Detail</th>
                    <th>Action</th>
                    <th>Cancel</th>
                    <th>Pending</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(tableData) &&
                    tableData.map((item, index) => (
                      <tr
                        key={index + 1}
                        style={{
                          fontWeight:
                            item.order_status == "old" ? "normal" : "bold",
                        }}
                        onClick={() => toggleReadStatus(item.id, "new")}
                      >
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            toggleReadStatus(item.id, item.order_status)
                          }
                        >
                          {item.order_status == "old" ? (
                            <i
                              className="fa fa-envelope-open"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            <i
                              className="fa fa-envelope"
                              aria-hidden="true"
                            ></i>
                          )}
                        </td>
                        <td>
                          <Link to={`/order-detail`} state={{ id: item.id }}>
                            {index + 1}
                          </Link>
                        </td>
                        <td>
                          <Link to={`/order-detail`} state={{ id: item.id }}>
                            {item.order_no}
                          </Link>
                        </td>
                        <td>
                          <Link to={`/order-detail`} state={{ id: item.id }}>
                            Rs. {item.bill}
                          </Link>
                        </td>
                        <td>
                          <Link to={`/order-detail`} state={{ id: item.id }}>
                            {item.rider}
                          </Link>
                        </td>
                        <td>
                          <Link to={`/order-detail`} state={{ id: item.id }}>
                            {item.unique_id}
                          </Link>
                        </td>
                        <td>
                          <Link to={`/order-detail`} state={{ id: item.id }}>
                            {item.name}
                          </Link>
                        </td>
                        <td>
                          <Link to={`/order-detail`} state={{ id: item.id }}>
                            {item.phone}
                          </Link>
                        </td>
                        {/* <td><Link to={`/order-detail`} >{item.email}</Link></td> */}
                        <td>
                          <Link to={`/order-detail`} state={{ id: item.id }}>
                            {item.address}
                          </Link>
                        </td>
                        <td>
                          <Link to={`/order-detail`} state={{ id: item.id }}>
                            {item.created_at}
                          </Link>
                        </td>
                        {/* <td><Link to={`/order-detail`} >{item.instruction}</Link></td> */}
                        <td>
                          <Link
                            className="btn btn-sm btn-primary"
                            to={`/order-detail`}
                            state={{ id: item.id }}
                          >
                            View Detail
                          </Link>
                        </td>
                        <td>
                          {item.status == "cancelled" ||
                          item.status == "delivered" ? (
                            "N/A"
                          ) : item.status == "transit" ? (
                            "Rider Assigned"
                          ) : (
                            <a
                              className="btn btn-sm btn-primary"
                              href="javascript:void(0)"
                              onClick={() => statusUpdate(item.id, item.status)}
                            >
                              {item.status == "pending"
                                ? "Pack Item"
                                : item.status == "packing" && "Assign Rider"}
                            </a>
                          )}
                        </td>
                        <td>
                          {item.status !== "cancelled" &&
                          item.status !== "delivered" ? (
                            <button
                              className="btn btn-primary"
                              onClick={() => statusUpdate(item.id)}
                            >
                              Cancel Order
                            </button>
                          ) : (
                            "N/A"
                          )}
                        </td>
                        <td>
                          {item.status === "cancelled" ? (
                            <button
                              className="btn btn-primary"
                              onClick={() => statusUpdate(item.id, "cancelled")}
                            >
                              Pending Order
                            </button>
                          ) : (
                            "N/A"
                          )}
                        </td>
                        <td style={{ color: getStatusColor(item.status) }}>
                          <strong>{item.status.toUpperCase()}</strong>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Footer />
      </div>

      <style jsx>{`
        .btn.btn-orange {
          background-color: #f44c00;
          border-color: #f44c00;
          padding: 14px 28px;
          font-size: 16px;
          font-weight: 600;
          text-transform: uppercase;
          border-radius: 30px;
          transition: background-color 0.3s, border-color 0.3s;
          display: inline-block;
          margin-bottom: 20px;
          color: #fff;
        }
        .btn.btn-orange:hover {
          background-color: #d43f00;
          border-color: #d43f00;
          color: #fff;
        }

        .btn.btn-orange:focus {
          outline: none;
        }

        .date-picker-container {
          position: relative;
          display: inline-block;
        }
        .date-input {
          padding: 8px 12px;
          padding-left: 40px;
          font-size: 16px;
          border: 1px solid #ddd;
          border-radius: 30px;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
          transition: border-color 0.3s ease;
          outline: none;
        }
        .date-input:hover,
        .date-input:focus {
          border-color: #f44c00;
        }
        .calendar-icon {
          position: absolute;
          top: 50%;
          left: 12px;
          transform: translateY(-50%);
          color: #f44c00;
          font-size: 18px;
        }
        /* General button styles */
        .btn-orange {
          background-color: #f44c00;
          color: white;
          border: none;
          padding: 12px 20px;
          cursor: pointer;
          transition: all 0.3s ease;
          text-align: center;
          font-size: 15px;
          font-weight: 600;
          text-transform: uppercase;
          border-radius: 25px;
          width: 100%; /* Full width within container */
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          margin-bottom: 12px;
        }

        /* Hover and active states */
        .btn-orange:hover {
          background-color: #d43f00;
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
        }

        .btn-orange.active {
          background-color: #62aa12;
          color: #fff;
        }

        /* Button Group Styling */
        .button-group {
          display: flex;
          flex-direction: column; /* Stack buttons vertically */
          align-items: stretch; /* Stretch buttons to fill container width */
          padding: 15px;
          background: #f8f9fa; /* Light background for contrast */
          border-radius: 15px;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
          gap: 10px; /* Space between buttons */
          margin-bottom: 20px;
        }

        /* Responsive Design */
        @media (min-width: 601px) {
          .button-group {
            flex-direction: row; /* Row layout for wider screens */
            flex-wrap: wrap;
            justify-content: center; /* Center-align buttons */
          }

          .button-group .btn-orange {
            flex: 1 1 200px; /* Set minimum button width on medium screens */
            max-width: 300px; /* Max width for wider screens */
            margin-bottom: 0; /* Remove margin since gap is already applied */
          }
        }

        @media (max-width: 600px) {
          .button-group .btn-orange {
            flex: 1 1 100%;
            margin-bottom: 10px;
          }
        }
      `}</style>
    </>
  );
}
