import React, { useEffect, useState } from 'react'
import Sidebar from '../Includes/Sidebar'
import Footer from '../Includes/Footer'
import GeneralService from '../../services/general.service';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';

export default function ManageNotification({ route }) {
    const [rowId, setRowId] = useState(0);
    const [heading, setHeading] = useState();
    const [body, setBody] = useState();
    const [body2, setBody2] = useState();
    const [button, setButton] = useState();
    const [notificationStatus, setNotificationStatus] = useState();
    const [headingError, setHeadingError] = useState(null);
    const [priceError, setPriceError] = useState(null);
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false); // New state for loading
    const state = useSelector((state) => state.stateVals);
    const { id: adminId } = state;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await GeneralService.getNotification();
                // console.log(response.data.response.name);
                setRowId(response.data.response.id);
                setHeading(response.data.response.heading);
                setBody(response.data.response.body);
                setBody2(response.data.response.body2);
                setButton(response.data.response.button);
                setNotificationStatus(response.data.response.notification_status);
                console.log(JSON.stringify(response.data.response));
            } catch (error) {
                console.error('Error fetching table data:', error);
            }
        };

        fetchData();
    }, []);

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } =
        useFormik({
            enableReinitialize: true,
            initialValues: {
                heading: heading,
                body: body,
                body2: body2,
                button: button,
                notificationStatus: notificationStatus,
            },
            // validationSchema: deliveryUpdateSchema,
            onSubmit: (values, action) => {
                formSubmit(values, action);
            },
        });

    const formSubmit = (values, action) => {
        const uploadForm = async (values) => {
            console.log(values);

            try {
                const response = await GeneralService.updateNotification(values, rowId, adminId);
                // navigation.navigate("/view-category");
                setMessage("Data updated");

            } catch (err) {
                console.log(err);
                setMessage("Error updating data");
            }
        }

        uploadForm(values);
    }

    const handleNumbers = (event) => {
        const inputValue = event.target.value;
        // Only allow numeric input
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        setFieldValue("price", numericValue);
    };

    return (
        <>
            <div className="content">
                <Sidebar />
                <div className="container-fluid pt-4 px-4">
                    <div className="bg-light text-center rounded p-4">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <h6 className="mb-0">Manage Announcement</h6>
                        </div>
                        {message && (
                            <div className="alert alert-success" role="alert">
                                {message}
                            </div>
                        )}
                        <form onSubmit={handleSubmit} noValidate>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="form-floating mb-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="heading"
                                            name="heading"
                                            placeholder="Heading Text"
                                            value={values.heading || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <label htmlFor="heading">Heading Text</label>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className="form-floating mb-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="body2"
                                            name="body2"
                                            placeholder="Line 1 Text"
                                            value={values.body2 || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <label htmlFor="heading">Line 1 Text</label>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className="form-floating mb-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="body"
                                            name="body"
                                            placeholder="Line 2 Text"
                                            value={values.body || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <label htmlFor="heading">Line 2 Text</label>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className="form-floating mb-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="button"
                                            name="button"
                                            placeholder="Button Text"
                                            value={values.button || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <label htmlFor="heading">Button Text</label>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-floating mb-4">
                                        <select
                                            className='form-select'
                                            name="notificationStatus"
                                            id="notificationStatus"
                                            value={values.notificationStatus}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        >
                                            <option value="yes">On</option>
                                            <option value="no">Off</option>
                                        </select>
                                        <label htmlFor="notification_status">Announcement Status</label>

                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <button className="btn btn-submit" type="submit" disabled={isLoading}>
                                        {isLoading ? "Submitting..." : "Update"}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <Footer />
            </div>
            <style jsx>{`
            
                .btn-submit {
                    background-color: #f44c00;
                    border-color: #f44c00;
                    padding: 14px 28px;
                    font-size: 16px;
                    font-weight: 600;
                    text-transform: uppercase;
                    border-radius: 30px;
                    transition: background-color 0.3s, border-color 0.3s;
                    display: inline-block;
                    margin-top: 10px;
                    color: #fff
                }

                .btn-submit:hover {
                    background-color: #d43f00;
                    border-color: #d43f00;
                    color: #fff;
                }

                .btn-submit:focus {
                    outline: none;
                }
            `}</style>

        </>
    )
}